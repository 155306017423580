import React from 'react'
import LandingBanner from '../components/landingpage/LandingBanner'
import { ParallaxProvider,  ParallaxBanner, ParallaxBannerLayer } from 'react-scroll-parallax';
import LandingHeader from '../components/header/LandingHeader';
import '../css/landingpage.css'
import bg from '../images/bg1.jpg'
import bg2 from '../images/bg2.jpg'
import ServicesSec from '../components/landingpage/ServicesSec';
// import { Parallax } from 'react-parallax';
import Featuresec from '../components/landingpage/Featuresec';
import Technology_sec from '../components/landingpage/Technology_sec';
import Testimonial_sec from '../components/landingpage/Testimonial_sec';
import Contact_sec from '../components/landingpage/Contact_sec';
import FooterLanding from '../components/landingpage/FooterLanding';

const LandingPage = () => {
  return (
    <>
    <LandingHeader/>
    <LandingBanner/>
    <ServicesSec/>  
    <Featuresec/>  
    <Technology_sec/>
    <Testimonial_sec/>
    <Contact_sec/>   
    <FooterLanding/>
    </>
  )
}

export default LandingPage