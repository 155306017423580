import React, { useState } from 'react';
import bg from '../../images/4th-1.png';
import { Parallax } from 'react-parallax';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Contact_sec = () => {
  // State ko initial values se initialize karna
  const [formData, setFormData] = useState({
    firstname: '',
    email: '',
    number: '',
    message: ''
  });

  // Input field se onChange event ko handle karne wala function
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    // State ko update karna
    setFormData({ ...formData, [name]: value });
  };

  // Form ko submit karne wala function
  const handleSubmit = (e) => {
    e.preventDefault();
    console.log('Form Data:', formData);
    postData(formData);   
    setFormData({
      first_name: '',
      email: '',
      number: '',
      message: ''
    });
    toast.success('Form submitted successfully!', {
      position: "top-right",
    });
  
  };

  const postData = async (data) => {
    try {
      const response = await fetch('https://recruitment.lemolite.com/contact-us', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization':'Bearer 2A8FD1E75BC9A346' 

        },
        body: JSON.stringify(data)
      });
      const responseData = await response.json();
      setFormData('');
      console.log('API Response:', responseData);
      // Handle response as needed
    } catch (error) {
      console.error('Error:', error);
    }
  };

  return (
    <>
      <div className="contactus_sec">
        <Parallax bgImage={bg} strength={300} disabled={window.innerWidth <= 1023} className='full_bg'> 
          <div className="container d-flex h-100 align-items-center">           
            <div className="land-contact-form">
              <h2 className='heading2 text-center'>Get In Touch With Us</h2>
              <form id="contactForm" onSubmit={handleSubmit}>
                <input type="hidden" name="action" value="home_contact_form" />
                <div className="row row_gap">
                  <div className="col-12">
                    <input type="text" name="first_name" placeholder="FIRST NAME" className="fill_input" value={formData.first_name} onChange={handleInputChange} required />
                  </div>
                  <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
                    <input type="email" name="email" placeholder="EMAIL" className="fill_input" value={formData.email} onChange={handleInputChange} required />
                  </div>
                  <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
                    <input type="text" name="number" placeholder="CONTACT NO" className="fill_input" value={formData.number} onChange={handleInputChange} required />
                  </div>
                  <div className="col-12">
                    <textarea name="message" cols="2" rows="8" placeholder="WRITE YOUR MESSAGE HERE..." className="fill_input" value={formData.message} onChange={handleInputChange}></textarea>
                  </div>
                  <div className="col-12 text-center">                    
                      <button type="submit" className='btns'>
                        <span className="label-up">Send</span>
                        <span className="label-up">Send</span>
                      </button>
                    
                  </div>
                </div>   
              </form>
            </div>
          </div>
        </Parallax>
        <ToastContainer />
      </div>
    </>
  );
}

export default Contact_sec;
