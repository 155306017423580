import React from 'react'
import bg from '../../images/3rd.png'
import { Parallax } from 'react-parallax';

import icon1 from '../../images/icon1.svg'
import icon2 from '../../images/icon2.svg'
import icon3 from '../../images/icon3.svg'
import icon4 from '../../images/icon4.svg'
import icon5 from '../../images/icon5.svg'
import icon6 from '../../images/icon6.svg'

const features = [
    {
      icon: icon1,
      title: 'Resume Scanner',
      description:'Analyzes resumes, identifies keywords, ensures ATS compatibility, and provides tailored suggestions for improvements.'
    },
    {
      icon: icon2,
      title: 'Keyword Matching',
      description: 'Analyzes job descriptions, identifies crucial keywords, matches resumes, and highlights gaps for improvements.'
    },
    {
      icon: icon3,
      title: 'Structured Data Output',
      description: 'Transforms resume data into a standardized, searchable, and analyzable format for easy access.'
    },
    {
      icon: icon4,
      title: 'Scalability',
      description: 'Scalable CVScanners handles high volume, ensures consistency, speed, adaptability, integrates with HR tools, and supports growth.'
    },
    {
      icon: icon5,
      title: 'Job Description & Resume Scan',
      description: 'Analyzes job specs, scans resumes, finds keywords, ATS-ready, highlights skills, provides metrics.'
    },
    {
      icon: icon6,
      title: 'Multiple User Roles',
      description: 'System supports role-based access, custom roles, secure data handling, and boosts efficiency for multiple users.'
    }
  ];


const Featuresec = () => {
    const colstyle = {
        padding: '0 40px'
      };
  return (
    <>
      <div className="feature_sec">
      <Parallax  bgImage={bg} strength={300} disabled={window.innerWidth <= 1023} className='full_bg'> 
        <div className="container"> 
            <div className="row row_gap">
                {features.map((feature, index) => (
                    <div key={index} className="col-lg-4 col-md-6 col-12" style={colstyle}>
                        <div className="feature_box text-center">
                            <img src={feature.icon} alt="" className='mb-2' />
                            <h3 className='heading3 mb-1'>{feature.title}</h3>
                            <p>{feature.description}</p>
                        </div>
                    </div>
                ))}
            </div>
        </div>
        </Parallax>
        </div>
        
    </>
  )
}

export default Featuresec