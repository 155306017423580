import React from 'react'
import bg from '../../images/5th.png'
import leftarrow from '../../images/left-icon.svg'
import rightarrow from '../../images/right-icon.svg'
import { Parallax } from 'react-parallax';

import Testimonial_Slider from "react-slick";
import TestimonialApi from '../../Api/TestimonialApi';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useState } from 'react'
// import $ from 'jquery';
const Testimonial_sec = () => {
  const [testimonialData, settestimonialData] = useState(TestimonialApi)
    const settings = {
        infinite: true,        
        autoplay: false,      
        autoplaySpeed: 2000,      
        speed: 500,
        arrows:true,       
        slidesToShow: 1,
        slidesToScroll: 1,
        prevArrow: <img src={leftarrow} alt="Previous" />,
        nextArrow: <img src={rightarrow} alt="Next" />        
      };

  return (
    <>
    <div className="teatimonial_sec">
    <Parallax  bgImage={bg} strength={300} disabled={window.innerWidth <= 1023} className='full_bg' > 
        <div className="container d-flex h-100">   
            <div className="row text-center row-gap">
                <div className="col-xl-6 col-lg-5 col-md-5 col-12"></div>
                    <div className="col-xl-6 col-lg-7 col-md-7 col-12">
                        <h2 className='heading2'>
                        Client’s Word
                        </h2>
                        <p>CVScanner's has transformed our hiring process. It swiftly analyzes resumes, saving us 
                            time and effort. The insights provided are invaluable for identifying top talent. 
                            It's user-friendly and essential for any HR team!</p>
                            <div className="testimonial_slider">
                            <Testimonial_Slider {...settings}> 
                            { testimonialData.map((client, item) => {
                                return(
                                    <div className='item' key={client.id}>                                
                                        <div className='slide_blog text-center'>
                                            <p>{client.review}</p>
                                            <div className="author_wrap d-flex gap-3 justify-content-center">                      
                                            {client.img && (
                                                <div className="author_img">
                                                    <img src={client.img} alt="img" />
                                                </div>
                                                )}
                                                <div className="author_name text-start">
                                                    <h6>{client.name}</h6>
                                                    {client.profession && (
                                                        <span className='heading6'>{client.profession}</span>
                                                    )}
                                                    
                                                </div>
                                            </div>
                                        </div>                                                       
                                    </div>     
                                )
                            })}                     
                            </Testimonial_Slider>
                            </div>
                    </div>
                 </div>
        </div>                        
    </Parallax>
    </div>
    </>
  )
}

export default Testimonial_sec