import React from 'react'
import bg from '../../images/1st.png'
import { Link } from 'react-router-dom';
import { Parallax } from 'react-parallax';

// import { ParallaxProvider,  ParallaxBanner, ParallaxBannerLayer, Parallax } from 'react-scroll-parallax';

const LandingBanner = () => {
  return (
    <div className="banner_sec" >
      <Parallax  bgImage={bg} strength={300} disabled={window.innerWidth <= 1023} className='full_bg'>
        <div className="content">
          <h1>Welcome to CVScanner Optimize Your Resume for Job</h1>
          <div className="btns">
              <Link to="/" >
              <span className="label-up">Let’s Talk</span>       
              <span className="label-up">Let’s Talk</span>
              </Link>
        </div>     
        </div>
        </Parallax>
    
   
    

  </div>
    
  )
}

export default LandingBanner