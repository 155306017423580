

const TestimonialApi = [
    {
        id: 1,
        review:'Revolutionized our hiring process, saved time and found top talent effortlessly. ',        
        img:'../images/user-cv.png',        
        name:'Alex Johnson',
        profession:' ',
    },
    {
        id: 2,
        review:'Game-changer for our recruitment agency, streamlining candidate selection with precision. ',        
        img:'../images/user-cv.png',        
        name:'Sarah Chen',        
        profession:' ',
    },
    {
        id: 3,
        review:'AI-driven model boosted efficiency, delivering exceptional hires for our company.',        
        img:'../images/user-cv.png',        
        name:'Ryan Patel',        
        profession:'',

    },
    
] 
export default TestimonialApi