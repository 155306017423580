import React from 'react'
import logo from '../../images/logo.svg';
import { Link } from 'react-router-dom';
import '../header/landingHeader.css'
import { useState, useEffect } from 'react';

const LandingHeader = () => {
  const [isSticky, setIsSticky] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 100) {
        setIsSticky(true);
      } else {
        setIsSticky(false);
      }
    };

    window.addEventListener('scroll', handleScroll);

    // Clean up the event listener
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []); // Empty dependency array ensures the effect runs only once
  return (
    <>
    <header className={isSticky ? 'sticky' : ''}>
        <div className="container">            
        
            <nav className="navbar navbar-expand-lg navbar-light justify-content-between ">
  <Link className="navbar-brand" to="/">
    <img src={logo} alt="logo" />
  </Link>
 
  <button
    className="navbar-toggler"
    type="button"
    data-bs-toggle="collapse"
    data-bs-target="#navbarNav"
    aria-controls="navbarNav"
    aria-expanded="false"
    aria-label="Toggle navigation">
      <span></span>
      <span></span>
      <span></span>
  </button>
  <div className="collapse navbar-collapse justify-content-end" id="navbarNav">
    <ul className="navbar-nav ml-auto">
      <li className="nav-item">
      <span className='dot'></span>
        <Link className="nav-link" to="/">
          Home
        </Link>
      </li>
      <li className="nav-item">
      <span className='dot'></span>
        <Link className="nav-link" to="/">
          ATS Resume
        </Link>
      </li>
      <li className="nav-item">
      <span className='dot'></span>
        <Link className="nav-link" to="/">
          About Us
        </Link>
      </li>
      <li className="nav-item">
      <span className='dot'></span>
        <Link className="nav-link" to="/">
          Contact Us
        </Link>
      </li>
    </ul>
    <div className="btns">
      <Link to="/">
        <span className="label-up">Login</span>
        <span className="label-up">Login</span>

      </Link>
    </div>
  </div>
</nav>

        </div>

    </header>
    </>
  )
}

export default LandingHeader