import React from 'react'
import bg from '../../images/footerbg.png'
import { ParallaxProvider,  ParallaxBanner, ParallaxBannerLayer, Parallax } from 'react-scroll-parallax';
import { Link } from 'react-router-dom';
const FooterLanding = () => {
    const containerStyle = {
    background: `url(${bg})`,
    backgroundSize: 'cover', // Image ka size customize karein
    backgroundPosition: 'center', 
    }
  return (
    <>
  
      
<div className="La-footer" style={containerStyle} image={bg}>
    <div className="container" >
        <div className="row-gap"> 
        <h2 className='heading2'>
        Explore More Features
        </h2>
        <div className="btns">
                  <Link to="/" >
                  <span className="label-up">Let’s Talk</span>
                  <span className="label-up">Let’s Talk</span>
                  </Link>
                </div>
        </div>
       
        </div>
        <div className="copyright_blok">
             <p>2024 CVScanners. All rights reserved</p>
        </div>
        </div>
   
    
        </>
  )
}

export default FooterLanding