import React from 'react'
import bg from '../../images/2nd.png'
import { Link } from 'react-router-dom';
// import { ParallaxProvider,  ParallaxBanner, ParallaxBannerLayer, Parallax } from 'react-scroll-parallax';
import { Parallax } from 'react-parallax';

const ServicesSec = () => {
  return (
    <div className="service_sec">
      <Parallax  bgImage={bg} strength={300} disabled={window.innerWidth <= 1023} className='full_bg'> 
        <div className="container">        
        <div className="content text-center w70">
          <h2 className='heading2'>Why Should You Choose CVScanner Services</h2>
          <p>Our platform prioritizes efficiency. With CVScanners, you can save valuable 
            time by automating the resume screening process. Our advanced technology swiftly analyzes resumes,
             extracting key qualifications, and presenting them in an easy-to-read format. This efficiency allows you to focus 
            your attention on the most promising candidates.</p>
          <div className="btns">
              <Link to="/" >
              <span className="label-up">About Us</span>
              <span className="label-up">About Us</span>
              </Link>
        </div>     
        </div>
        </div>
        </Parallax>
  </div>
    
  )
}

export default ServicesSec