import './App.css';
import '../node_modules/bootstrap/dist/css/bootstrap.min.css'
import '../node_modules/bootstrap/dist/js/bootstrap.bundle'
import '../src/css/commonstyle.css'
import '../src/css/style.css'
import { Route, Routes } from 'react-router-dom';
import Home from './pages/Home';
import LandingPage from './pages/LandingPage';
import AtsResume from './pages/AtsResume';


function App() {
  return (
    <>
   <Routes>
        <Route path="/" element={<LandingPage/>} />       
        


</Routes>
      
      </>
  );

}

export default App;
      