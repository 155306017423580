import React from 'react'
import bg from '../../images/4th.png'
// import { ParallaxProvider,  ParallaxBanner, ParallaxBannerLayer, Parallax } from 'react-scroll-parallax';
import { Parallax, Background } from 'react-parallax';

const Technology_sec = () => {
  return (
    <>
     <div className="technology_sec">
     <Parallax  bgImage={bg} strength={300} disabled={window.innerWidth <= 1023} className='full_bg'> 
        <div className="container d-flex align-items-center h-100">  
        <div className="row">
          <div className=" col-xl-6 col-lg-7 col-md-6 col-12">
            <h2 className='heading2'>
            An Inspirational Technology
            </h2>
            <p>Our CVScanners leverages AI, machine learning, and natural language processing 
              for resume analysis. It extracts keywords, matches job descriptions, and structures 
              data for easy analysis. The system ensures user data security and integrates seamlessly 
              with other HR tools, making it a powerful tool for efficient and effective recruitment processes.</p>
          </div>
        </div>
        </div>
        </Parallax>
        </div>
    </>
  )
}

export default Technology_sec